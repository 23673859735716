<template>
  <b-row>
    <b-col sm="12" md="12">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >

            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refDataListTable"
          :items="fetchDatas"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #cell(name)="data">
            <span>
                <b-link
          :to="{
            name: 'physicalassets-realestate-view',
            params: { id: data.item.metadata_id },
          }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.meta.name || "" }}
        </b-link>
            </span>
          </template>
          <template #cell(size)="data">
            <span>
              {{ data.item.meta.readsize || "" }}
            </span>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ data.item.created_at || "" | moment("DD/MM/YYYY") }}
            </span>
          </template>
          <template #cell(actions)="data">
            <feather-icon
              :id="`document-row-${data.item.id}-preview-icon`"
              icon="DownloadIcon"
              size="16"
              class="mx-1"
              @click="downloadfun(data.item)"
            />
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

  <script>
import listaction from "@/views/Component/listaction.vue";
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useList from "./useList";
import StoreModule from "../StoreModule";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    listaction,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "physicalassets-realestate";
    const modulename = "Real Estate";
    const modulenamesub = "realestate";
    // Register module
    store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    const {
      fetchDatas,
      deletefun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      downloadfun,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      statusFilter,
      refetchData,
      resolveDataRoleVariant,
    } = useList();
    return {
      modulename,
      modulenamesub,
      fetchDatas,
      deletefun,
      downloadfun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      statusFilter,
      refetchData,
      avatarText,
      resolveDataRoleVariant,
      GENAPP_APP_STORE_MODULE_NAME,
    };
  },
  methods: {
    checkdeletefun(val) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
.per-page-selector {
  wicabletv: 90px;
}
.electricity-filter-select {
  min-wicabletv: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    wicabletv: 100px;
  }
}
</style>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
